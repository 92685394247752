<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <logo-text />
        </b-link>

        <b-card-title class="mb-1 text-center">
          Link verwerken...
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          <b-spinner />
        </b-card-text>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Terug naar aanmelden
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase/firebaseConfig";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import LogoText from "@core/layouts/components/LogoText.vue";
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    LogoText,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BSpinner,
  },
  created() {
    var mode = this.$route.query.mode;
    var code = this.$route.query.oobCode;
    if (mode && code) {
      switch (mode) {
        case "resetPassword": {
          this.$router.push({
            name: "auth-reset-password",
            query: {
              mode: mode,
              oobCode: code,
            },
          });
          break;
        }
        case "recoverEmail": {
          this.recoverEmail(code);
          break;
        }
        case "verifyEmail": {
          this.verifyEmail(code);
          break;
        }
      }
    }
  },
  methods: {
    recoverEmail(actionCode) {
      var restoredEmail = null;
      // Confirm the action code is valid.
      auth
        .checkActionCode(actionCode)
        .then((info) => {
          // Get the restored email address.
          restoredEmail = info["data"]["email"];

          // Revert to the old email.
          return auth.applyActionCode(actionCode);
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "E-mailadres hersteld!",
              text: "Meld je opnieuw aan",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "🤷🏻‍♂️ Er ging iets fout",
              text: error.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.$router.push({ name: "home" });
        });
    },
    verifyEmail(actionCode) {
      auth
        .applyActionCode(actionCode)
        .then((resp) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "E-mailadres geverifieerd!",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "🤷🏻‍♂️ Er ging iets fout",
              text: error.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.$router.push({ name: "home" });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
